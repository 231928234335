export const colorPalette = {
    blue : { //Robin's Egg Blue
    hex: "#96DED1",
    },
    orange : { //Coral
    hex: "#FF8A5B",
    },
    yellow : { //Naples Yellow
      hex: "#F8E16C",
    },
    green : { //Midnight Green
      hex: "#13505B",
    },
    black : { //Black
      hex: "#040404",
    },
}