import Babylon from './Engine';
import './App.css';

function App() {
  return (
    <div className="App">

            <Babylon  />

    </div>
  );
}

export default App;
