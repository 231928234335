/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import { colorPalette } from './config'

// import { ArcRotateCamera } from '@babylonjs/core/Cameras/freeCamera';
// import { Engine } from '@babylonjs/core/Engines/engine';
// import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight';
// import { DirectionalLight } from '@babylonjs/core/Lights/DirectionalLight';
// import { Vector3 } from '@babylonjs/core/Maths/math.vector';
// import { CreateGround } from '@babylonjs/core/Meshes/Builders/groundBuilder';
// //import { CreateSphere } from '@babylonjs/core/Meshes/Builders/sphereBuilder';
// import { Scene } from '@babylonjs/core/scene';
// import { Tools } from '@babylonjs/core/tools';

import {
	Engine,
	Scene,
	// Mesh,
	ArcRotateCamera,
	Vector3,
	//CreateGround,
	Tools,
	PBRMaterial,
	WebGPUEngine,
	Animation,
	// SceneLoader,
	Color3,
	//Color4,
	//  StandardMaterial,
	// MeshBuilder,

	ShadowGenerator,
	DirectionalLight,

	SSAORenderingPipeline,
	//  VertexBuffer,

	HemisphericLight,
	Mesh,

} from "@babylonjs/core"
//import "@babylonjs/loaders"

const Babylon = () => {

	const debug = true

	let scene = {}
	let shadowGenerator = {}
	let camera = {}
	let ssao = {}
	let ground
	let brush

	const renderCanvas = useRef(null)
	const webGL = useRef(false)
	// let resizeTimeout
	// let windowSize = []

	const palette = {}

	for (let colors in colorPalette) {
		palette[colors] = {}
		palette[colors].diffuse = Color3.FromHexString(colorPalette[colors].hex)
	}

	const sceneBuild = (engine, canvas) => {
		//scene
		scene = new Scene(engine)
		if (debug) {
			console.warn("Debug enabled, scene is exposed at window.scene")
			window.scene = scene
		}

		//babScene(scene)
		//camera
		camera = new ArcRotateCamera("camera", Tools.ToRadians(45), Tools.ToRadians(65), 50, Vector3.Zero(), scene)
		camera.setTarget(Vector3.Zero())
		camera.attachControl(canvas, true)
		camera.useAutoRotationBehavior = true;

		//lights
		const light = new DirectionalLight("dir01", new Vector3(-1, -2, 1), scene)
		light.position = new Vector3(60, 130, -65)
		light.intensity = 1

		new HemisphericLight("hLight", new Vector3(0, 1, 0), scene)

		//glow
		// const glow = new GlowLayer("glow", scene)
		// glow.intensity = 0.2


		//ambient occlusion
		let ssaoRatio = { ssaoRatio: 0.5, combineRatio: 1.0 }
		ssao = new SSAORenderingPipeline("ssao", scene, ssaoRatio)
		ssao.fallOff = 0.000001
		ssao.area = 1
		ssao.radius = 0.0001
		ssao.totalStrength = 1.0
		ssao.base = 0.5

		// Attach camera to the SSAO render pipeline
		//  scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssao", camera)

		//shadows
		shadowGenerator = new ShadowGenerator(1024, light)
		shadowGenerator.useExponentialShadowMap = true
		// shadowGenerator.setDarkness(0.5)


		//materials
		let pbr = new PBRMaterial()
		pbr.metallic = 0.1
		pbr.roughness = 0.1
		for (let colors in palette) {
			console.log(colors, palette[colors])
			const paletteMaterial = pbr.clone(colors)
			paletteMaterial.albedoColor = palette[colors].diffuse
			palette[colors].material = paletteMaterial
		}


		scene.clearColor = palette.green.diffuse


		// ground
		ground = Mesh.CreatePlane("ground1", 16, scene)
		ground.rotation = new Vector3(Tools.ToRadians(90), 0, 0)
		ground.material = palette.yellow.material
		ground.receiveShadows = true

		brush = Mesh.CreateBox("brush", 16, scene)



		buildPaths()


		//resize engine render on window resize release
		const handleResize = () => {
            engine.resize()
			// clearTimeout(resizeTimeout)
			// // eslint-disable-next-line react-hooks/exhaustive-deps
			// resizeTimeout = setTimeout(() => {
			// 	windowSize.current = [window.innerWidth, window.innerHeight]
			// 	engine.resize()
			// }, 200)
		}
		window.addEventListener('resize', handleResize)

		engine.runRenderLoop(() => {
			scene.render()
		})

		return () => {
			engine.dispose()
		}
	}


	const buildPaths = () => {
		let x = 0;
		let z = 0;
		let count = 512;
		let lastTile

		let lastMove
		

		const animateTile = (i) => {
			const frarmeRate = 30
			if (i >= count) {
				return;
			}

			if (Math.random() < 0.5) {
				if (Math.random() < 0.5 ) {
					if(lastMove === "x-"){
						x += 16;
						lastMove = "x+"
					}
					else{
						x -= 16;
						lastMove = "x-"
					}
				}
				else {
					x += 16;
					lastMove = "x-"
				}	
				
			} else {
				if (Math.random() < 0.5) {
					if(lastMove === "z-"){
						z += 16;
						lastMove = "z+"
					}
					else{
						if(lastMove === "z+"){
							z += 16;
							lastMove = "z-"
						}
						else{
							z -= 16;
							lastMove = "z-"
						}
					}
				} else {
					if(lastMove === "z+"){
						z -= 16;
						lastMove = "z-"
					}
					else{
						z += 16;
						lastMove = "z+"
					}
				}
			
			}

			console.log(lastMove)

			let tileClone 
			
			if(lastTile){
				tileClone = lastTile.clone("1")
				tileClone.position = lastTile?.position
				//tileClone.position.y = tileClone.position.y + 0.01
			} 
			else{
				tileClone =	ground.clone("1");
			}
			
			const getWeightedRandomKey = (currentKey, keys) => {
				const currentIndex = keys.indexOf(currentKey);
				const random = Math.random();

				if (random < 0.6) {
					// 60% chance to stay the same
					return currentKey;
				} else if (random < 0.8) {
					// 20% chance to go to the next key
					return keys[(currentIndex + 1) % keys.length];
				} else {
					// 20% chance to go to the previous key
					return keys[(currentIndex - 1 + keys.length) % keys.length];
				}
			};

			const keys = Object.keys(palette);
			const currentKey = tileClone.material.name; // Assuming the material name is the same as the key
			const randomKey = getWeightedRandomKey(currentKey, keys);
			tileClone.material = palette[randomKey].material;
			
			const slide = new Animation("slide", "position", 15, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CYCLE);
			const camSlide = new Animation("target", "target", 15, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CYCLE);

			let aniKeys = [];
			aniKeys.push({ frame: 0, value: tileClone.position });
			aniKeys.push({ frame: frarmeRate , value: new Vector3(x, tileClone.position.y + 0.01, z) });
			slide.setKeys(aniKeys);

			tileClone.animations = [];
			tileClone.animations.push(slide);
			const tileAnimatable = scene.beginAnimation(tileClone, 0, frarmeRate, false);

			let camKeys = [];
			camKeys.push({ frame: 0, value: camera.target });
			camKeys.push({ frame: frarmeRate, value: ground.position });
			camSlide.setKeys(aniKeys);
			camera.animations = [];
			camera.animations.push(camSlide);
			scene.beginAnimation(camera, 0, frarmeRate, false);

			scene.onBeforeRenderObservable.add(() => {
				brush.position = new Vector3(tileClone.position.x,tileClone.position.y + 8, tileClone.position.z)

			});
			tileAnimatable.onAnimationEndObservable.addOnce(() => {
				brush.material = tileClone.material
				lastTile = tileClone;
				animateTile(i + 1);
			});
		};

		animateTile(0);
	};




	//webGPU engine
	const createSceneGPU = async () => {
		const { current: canvas } = renderCanvas
		//	babCanvas(renderCanvas)
		const engine = new WebGPUEngine(canvas)
		await engine.initAsync()
		sceneBuild(engine, canvas)
	}

	//webGPU engine
	const createSceneWebGL = () => {
		const { current: canvas } = renderCanvas
		//	babCanvas(renderCanvas)
		const engine = new Engine(canvas, true)
		sceneBuild(engine, canvas)
	}


	function isSupportedBrowser() {

		let userAgent = navigator.userAgent
		if (
			(userAgent.includes("CriOS")) ||
			userAgent.includes("Android") ||
			userAgent.includes("SamsungBrowser") ||
			userAgent.includes("iPhone")

		) {
			return false
		}
		let chromeMatch = userAgent.match(/Chrome\/(\d+)/)
		let edgeMatch = userAgent.match(/Edge\/(\d+)/)
		let firefoxMatch = userAgent.match(/Firefox\/(\d+)/)
		let operaMatch = userAgent.match(/OPR\/(\d+)/)
		if (
			(chromeMatch && parseInt(chromeMatch[1], 10) >= 113) ||
			(edgeMatch && parseInt(edgeMatch[1], 10) >= 113) ||
			(firefoxMatch && parseInt(firefoxMatch[1], 10) >= 113) ||
			(operaMatch && parseInt(operaMatch[1], 10) >= 100)
		) {
			return true
		} else {
			return false
		}
	}





	//entry point
	useEffect(() => {
		if (Object.keys(scene).length === 0) {
			if (!isSupportedBrowser()) {
				console.warn("WebGPU not supported by browser")
				createSceneWebGL() //with webGL
				webGL.current = true
				return
			}
			if (!navigator.gpu) {
				console.warn("WebGPU not supported.")
				console.warn("Switching to WebGL")
				createSceneWebGL() //with webGL
				webGL.current = true
			}
			else {
				console.log("Using WebGPU")
				createSceneGPU() //with webGPU
			}
		}
	}, [])


	return (
		<>
			<canvas
				ref={renderCanvas}
				className="renderCanvas"
			>
			</canvas>
			<p id="renderType">{webGL.current ? "Browser or GPU not supported, falling back to WebGL from WebGPU" : "Running on WebGPU"}</p>
			<div className='version'>
				{/* <img src={logo} alt='logo'></img> */}
				<p id="version">SecretNerd'24 v0.0.1</p>
			</div>
		</>

	)
}

export default Babylon;